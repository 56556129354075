import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-voucher-modal-launcher',
    templateUrl: './voucher-modal-launcher.component.html',
})
export class VoucherModalLauncherComponent {

    @Input() organisationId!: string
    @Input() businessId!: string
    @Input() venueId!: string
    @Input() voucherId!: string
    venueColour?: string | null

    openModal() {
        window.parent.postMessage({
                type: 'open-voucher-modal',
                payload: {
                    organisationId: this.organisationId,
                    businessId: this.businessId,
                    venueId: this.venueId,
                    voucherId: this.voucherId,
                },
            },
            '*')
    }
}
