import { Address } from './Address'
import { BookingReason } from './BookingReason'
import { Event } from './Event'
import { Question, QuestionTypeNaturalOrder } from './Question'

export class Venue {

    constructor(
        public id: string,
        public displayName: string,
        public phoneNumber: string | null,
        public emailAddress: string | null,
        public address: Address,
        public bookingInterval: number,
        public minLargePartySize: number | null,
        public largePartyMessage: string | null,
        public noBookingSlotAvailableMessage: string | null,
        public shortPreBookingWindowMessage: string | null,
        public currencyCode: string | null,
        public organisationId: string,
        public businessId: string,
        public paymentsAccountId: string | null,
        public cancellationChargeAmount: number | null,
        public cancellationChargeCutOffHours: number | null,
        public depositAmount: number | null,
        public depositRefundCutOffDays: number | null,
        public phoneNumberRequired: boolean,
        public primaryColour: string | null,
        public pinnedEventId: string | null,
        public areaBookingOrder: string[],
        public reasons: BookingReason[],
        public questions: Question[],
        public events: Event[]
    ) { }

    reasonWithId(id: string): BookingReason | null {
        return this.reasons.find(reason => reason.id === id) || null
    }

    reasonsUsedOnDate(dateTime: Date): BookingReason[] {
        return this.reasons
            .filter(r => r.usedOnDate(dateTime))
            .sort((a, b) => a.displayOrder - b.displayOrder)
    }

    reasonsUsedOnDateTime(dateTime: Date): BookingReason[] {
        return this.reasons
            .filter(r => r.usedOnDateTime(dateTime))
            .sort((a, b) => a.displayOrder - b.displayOrder)
    }

    eventWithId(id: string): Event | null {
        return this.events.find(event => event.id === id) || null
    }

    pinnedEvent(): Event | null {
        return this.events.find(event => event.id === this.pinnedEventId) || null
    }

    questionsToAskWhenBookingForConfiguration(
        reasonId: string | null,
        eventId: string | null
    ): Question[] {
        return this.questions
            .filter(question => {
                if (reasonId !== null && eventId === null) {
                    return question.reasonIds.length === 0 || question.reasonIds.includes(reasonId)
                }
                if (reasonId === null && eventId !== null) {
                    const event = this.eventWithId(eventId)
                    return event?.questionIds.includes(question.id) || false
                }
                return true
            })
            .sort((a, b) => {
                const aIndex = QuestionTypeNaturalOrder.indexOf(a.type)
                const bIndex = QuestionTypeNaturalOrder.indexOf(b.type)
                return aIndex - bIndex
            })
    }

    cancellationChargeCutOffHoursForConfiguration(selectedEventId: string | null): number | null {
        if (selectedEventId === null) {
            return this.cancellationChargeCutOffHours
        }
        const event = this.eventWithId(selectedEventId)
        return event?.cancellationChargeCutOffHours || null
    }

    depositRefundCutOffDaysForConfiguration(selectedEventId: string | null): number | null {
        if (selectedEventId === null) {
            return this.depositRefundCutOffDays
        }
        const event = this.eventWithId(selectedEventId)
        return event?.depositRefundCutOffDays || null
    }
}
