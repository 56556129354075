import { Component, EventEmitter, Input, OnInit } from '@angular/core'
import { environment } from '../../environments/environment'
import { Venue } from '../domain/Venue'
import { VenueService } from '../services/venue.service'
import { combineLatestWith, map, mergeMap, Observable, takeUntil } from 'rxjs'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Voucher } from '../domain/Voucher'
import { VoucherService } from '../services/voucher.service'
import { Helper } from '../utilities/helper'
import { SKU } from '../domain/SKU'
import { markAllFormControlsAsTouched } from '../utilities/form-touched'

@Component({
    selector: 'app-voucher-modal',
    templateUrl: './voucher-modal.component.html',
})
export class VoucherModalComponent implements OnInit {

    @Input() organisationId!: string
    @Input() businessId!: string
    @Input() venueId!: string
    @Input() voucherId!: string
    form!: FormGroup
    isLoading = false
    marketingUrl = environment.marketingUrl
    venue$!: Observable<Venue>
    voucher$!: Observable<Voucher>
    selectedSKU: SKU | null = null
    private onDestroy$ = new EventEmitter<void>()

    constructor(
        private fb: FormBuilder,
        private venueService: VenueService,
        private voucherService: VoucherService
    ) { }

    ngOnInit() {
        this.venue$ = this.venueService.getWithId(this.venueId)
        this.voucher$ = this.venue$.pipe(
            mergeMap(venue => {
                return this.voucherService.getWithId(venue, this.voucherId)
            })
        )
        this.form = this.makeForm()
        this.bindFormToVoucherSelectedSKU()
    }

    dismiss() {
        window.parent.postMessage(
            {
                type: 'close-booking-modal',
                payload: {
                },
            },
            '*'
        )
    }

    goToCheckout() {
        this.form.markAllAsTouched()
        this.form.updateValueAndValidity()
        if (this.form.invalid) {
            return
        }
    }

    private makeForm() {
        return this.fb.group({
            skuId: [null, [Validators.required]],
        })
    }

    private bindFormToVoucherSelectedSKU() {
        this.form.get('skuId')?.valueChanges
            .pipe(
                takeUntil(this.onDestroy$),
                combineLatestWith(this.voucher$),
            )
            .subscribe(([skuId, voucher]) => {
                this.selectedSKU = voucher.getSKUWithId(skuId)
            })
    }

    protected readonly Helper = Helper
}
