export class Table {
    public reservable: boolean
    public minimumSeats: number
    public maximumSeats: number
    public wheelchairAccessible: boolean | null
    public dogFriendly: boolean | null

    constructor(
        reservable: boolean,
        minimumSeats: number,
        maximumSeats: number,
        wheelchairAccessible: boolean | null,
        dogFriendly: boolean | null
    ) {
        this.reservable = reservable
        this.minimumSeats = minimumSeats
        this.maximumSeats = maximumSeats
        this.wheelchairAccessible = wheelchairAccessible
        this.dogFriendly = dogFriendly
    }
}
