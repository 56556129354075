import { SKU } from './SKU'

export class Voucher {

    constructor(
        public id: string,
        public displayName: string,
        public description: string | null,
        public skus: SKU[]
    ) { }

    get isOnSale(): boolean {
        return this.skus.some(sku => sku.onSale)
    }

    get lowestPricedSKU(): SKU | null {
        return this.skus.reduce((lowestPricedSKU: SKU | null, sku) => {
            if (lowestPricedSKU === null || sku.price < lowestPricedSKU.price) {
                return sku
            } else {
                return lowestPricedSKU
            }
        }, null)
    }

    get skusSortedByPriceLowestFirst(): SKU[] {
        return this.skus.slice().sort((a, b) => a.price - b.price)
    }

    getSKUWithId(skuId: string): SKU | null {
        return this.skus.find(sku => sku.id === skuId) || null
    }
}
