/**
 * Reduce the invocation of the supplied function so that it is only invoked
 * once within a given timeout.
 *
 * If `wait` is `0`, the function will be invoked during the next tick.
 * If `options.leading` is false or not provided, the function will be invoked
 * N milliseconds after the last invocation of the returned function where
 * N is the `timeout` value.
 * If `options.leading` is true, the function will be invoked immediately upon
 * the first invocation of the returned function and not again for N milliseconds
 * where N is the `timeout` value.
 * @param {function} func Function whose invocation should be limited so it is only invoked
 * once within a given timeout period.
 * @param {number} timeout Time in milliseconds that the function should only be invoked
 * once within.
 * @param {object} options Debounce options
 * @param {boolean} options.leading Forces the function to be invoked on the first
 * invocation of the returned function instead of N milliseconds after the last
 * invocation.
 * @returns {function} A wrapping function that will ensure the provided function
 * is invoked only once within the given timeout.
 */
export function debounce(func) {
  var _this = this;
  let timeout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 500;
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  const leading = options?.leading || false;
  let timer;
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    if (leading && timer === undefined) {
      func.apply(_this, args);
      timer = setTimeout(() => {
        timer = clearTimeout(timer);
      }, timeout);
    }
    if (!leading) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(_this, args);
      }, timeout);
    }
  };
}

/**
 * Reduce the invocation of the supplied function so that it is only invoked
 * once.
 * @param {function} func Function whose invocation should be limited so it is only invoked
 * once.
 * @returns {function} A wrapping function that will ensure the provided function
 * is invoked only once.
 */
export function single(func) {
  var _this2 = this;
  let called = false;
  return function () {
    if (!called) {
      called = true;
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }
      func.apply(_this2, args);
    }
  };
}