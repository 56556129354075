import { AnswerDTO } from './AnswerDTO'
import { BookingSlotDTO } from './BookingSlotDTO'
import { ContactDetailsDTO } from './ContactDetailsDTO'

export class BookingRequestDTO {

    constructor(
        public bookingSlot: BookingSlotDTO,
        public contact: ContactDetailsDTO,
        public partySize: number,
        public notes: string | null,
        public agreedToMarketing: boolean,
        public agreedToTerms: boolean,
        public setupIntentId: string | null,
        public reasonId: string | null,
        public eventId: string | null,
        public answers: AnswerDTO[],
        public depositPaymentIntentId: string | null,
        public requiresWheelchairAccess: boolean,
        public requiresDogFriendly: boolean
    ) { }
}
