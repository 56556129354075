<app-spinner-overlay [showSpinner]="isSendingFeedback">
    <div class="container col-sm-10 col-md-8 col-lg-6 col-xl-5 my-2">
        <form
            [formGroup]="feedbackForm"
            class="vstack gap-3"
        >
            <h5 class="fw-semibold text-muted m-0 align-self-center">
                {{ booking.venueName }}
            </h5>
            <h5 class="fw-semibold m-0">
                How was your experience?
            </h5>
            <div class="vstack">
                <div class="rounded-top bg-light p-3">
                    <div class="vstack gap-2">
                        <div class="vstack gap-3">
                            <span>
                                @if (booking.bookingName.length > 0) {
                                    Hi {{ booking.bookingName }},
                                } @else {
                                    Hi there,
                                }
                            </span>
                            <span>
                                Thank you for visiting
                                {{ booking.venueName}}
                                on
                                <span class="fw-semibold">
                                    {{ booking.dateTime | date : 'shortDate' }}
                                </span>
                                at
                                <span class="fw-semibold">
                                    {{ booking.dateTime | date : 'shortTime' }}
                                </span>.
                            </span>
                        </div>
                        <span class="text-muted">
                            We'd love it if you'd take just a moment to tell us about your experience with us.
                        </span>
                    </div>
                </div>
                <div class="rounded-bottom border-start border-end border-bottom border-light-subtle p-3">
                    <div class="vstack gap-3">
                        <div class="vstack gap-1">
                            <span class="form-label">
                                How did we do overall?
                                @if(feedbackForm.get('rating')?.value) {
                                    <span class="ms-2 fw-semibold">
                                        {{ feedbackForm.get('rating')?.value | ratingName }}
                                    </span>
                                }
                            </span>
                            <div class="vstack gap-2">
                                <div
                                    class="d-flex flex-column flex-sm-row flex-sm-row-reverse justify-content-around gap-2 gap-sm-3"
                                    role="group"
                                >
                                    <div class="d-flex flex-fill">
                                        <input
                                            type="radio"
                                            class="btn-check"
                                            formControlName="rating"
                                            id="btn-excellent"
                                            autocomplete="off"
                                            [value]="5"
                                        >
                                        <label class="btn btn-outline-excellent flex-fill" for="btn-excellent">
                                            {{ 5 | ratingName }}
                                        </label>
                                    </div>
                                    <div class="d-flex flex-fill">
                                        <input
                                            type="radio"
                                            class="btn-check"
                                            formControlName="rating"
                                            id="btn-good"
                                            autocomplete="off"
                                            [value]="4"
                                        >
                                        <label class="btn btn-outline-good flex-fill" for="btn-good">
                                            {{ 4 | ratingName }}
                                        </label>
                                    </div>
                                    <div class="d-flex flex-fill">
                                        <input
                                            type="radio"
                                            class="btn-check"
                                            formControlName="rating"
                                            id="btn-fair"
                                            autocomplete="off"
                                            [value]="3"
                                        >
                                        <label class="btn btn-outline-fair flex-fill" for="btn-fair">
                                            {{ 3 | ratingName }}
                                        </label>
                                    </div>
                                    <div class="d-flex flex-fill">
                                        <input
                                            type="radio"
                                            class="btn-check"
                                            formControlName="rating"
                                            id="btn-poor"
                                            autocomplete="off"
                                            [value]="2"
                                        >
                                        <label class="btn btn-outline-poor flex-fill" for="btn-poor">
                                            {{ 2 | ratingName }}
                                        </label>
                                    </div>
                                    <div class="d-flex flex-fill">
                                        <input
                                            type="radio"
                                            class="btn-check"
                                            formControlName="rating"
                                            id="btn-very-poor"
                                            autocomplete="off"
                                            [value]="1"
                                        >
                                        <label class="btn btn-outline-very-poor flex-fill" for="btn-very-poor">
                                            {{ 1 | ratingName }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vstack gap-2">
                            <label
                                class="form-label"
                            >
                                How would you rate the following?
                            </label>
                            <!-- Food rating -->
                            <div class="row">
                                <div class="form-text col-2 col-sm-3">
                                    Food
                                </div>
                                <div class="col-10 col-sm-9 hstack gap-1 gap-sm-3" role="group">
                                    <input
                                        type="radio"
                                        class="btn-check"
                                        id="foodRating1"
                                        formControlName="food"
                                        autocomplete="off"
                                        [value]="1"
                                    >
                                    <label
                                        class="btn btn-light flex-fill"
                                        for="foodRating1"
                                    >
                                        1
                                    </label>
                                    <input
                                        type="radio"
                                        class="btn-check"
                                        id="foodRating2"
                                        formControlName="food"
                                        autocomplete="off"
                                        [value]="2"
                                    >
                                    <label
                                        class="btn btn-light flex-fill"
                                        for="foodRating2"
                                    >
                                        2
                                    </label>
                                    <input
                                        type="radio"
                                        class="btn-check"
                                        id="foodRating3"
                                        formControlName="food"
                                        autocomplete="off"
                                        [value]="3"
                                    >
                                    <label
                                        class="btn btn-light flex-fill"
                                        for="foodRating3"
                                    >
                                        3
                                    </label>
                                    <input
                                        type="radio"
                                        class="btn-check"
                                        id="foodRating4"
                                        formControlName="food"
                                        autocomplete="off"
                                        [value]="4"
                                    >
                                    <label
                                        class="btn btn-light flex-fill"
                                        for="foodRating4"
                                    >
                                        4
                                    </label>
                                    <input
                                        type="radio"
                                        class="btn-check"
                                        id="foodRating5"
                                        formControlName="food"
                                        autocomplete="off"
                                        [value]="5"
                                    >
                                    <label
                                        class="btn btn-light flex-fill"
                                        for="foodRating5"
                                    >
                                        5
                                    </label>
                                </div>
                            </div>

                            <!-- Service rating -->
                            <div class="row">
                                <div class="form-text col-2 col-sm-3">
                                    Service
                                </div>
                                <div class="col-10 col-sm-9 hstack gap-1 gap-sm-3" role="group">
                                    <input
                                        type="radio"
                                        class="btn-check"
                                        id="serviceRating1"
                                        formControlName="service"
                                        autocomplete="off"
                                        [value]="1"
                                    >
                                    <label
                                        class="btn btn-light flex-fill"
                                        for="serviceRating1"
                                    >
                                        1
                                    </label>
                                    <input
                                        type="radio"
                                        class="btn-check"
                                        id="serviceRating2"
                                        formControlName="service"
                                        autocomplete="off"
                                        [value]="2"
                                    >
                                    <label
                                        class="btn btn-light flex-fill"
                                        for="serviceRating2"
                                    >
                                        2
                                    </label>
                                    <input
                                        type="radio"
                                        class="btn-check"
                                        id="serviceRating3"
                                        formControlName="service"
                                        autocomplete="off"
                                        [value]="3"
                                    >
                                    <label
                                        class="btn btn-light flex-fill"
                                        for="serviceRating3"
                                    >
                                        3
                                    </label>
                                    <input
                                        type="radio"
                                        class="btn-check"
                                        id="serviceRating4"
                                        formControlName="service"
                                        autocomplete="off"
                                        [value]="4"
                                    >
                                    <label
                                        class="btn btn-light flex-fill"
                                        for="serviceRating4"
                                    >
                                        4
                                    </label>
                                    <input
                                        type="radio"
                                        class="btn-check"
                                        id="serviceRating5"
                                        formControlName="service"
                                        autocomplete="off"
                                        [value]="5"
                                    >
                                    <label
                                        class="btn btn-light flex-fill"
                                        for="serviceRating5"
                                    >
                                        5
                                    </label>
                                </div>
                            </div>

                            <!-- Atmosphere rating -->
                            <div class="row">
                                <div class="form-text col-2 col-sm-3">
                                    Atmosphere
                                </div>
                                <div class="col-10 col-sm-9 hstack gap-1 gap-sm-3" role="group">
                                    <input
                                        type="radio"
                                        class="btn-check"
                                        id="atmosphereRating1"
                                        formControlName="atmosphere"
                                        autocomplete="off"
                                        [value]="1"
                                    >
                                    <label
                                        class="btn btn-light flex-fill"
                                        for="atmosphereRating1"
                                    >
                                        1
                                    </label>
                                    <input
                                        type="radio"
                                        class="btn-check"
                                        id="atmosphereRating2"
                                        formControlName="atmosphere"
                                        autocomplete="off"
                                        [value]="2"
                                    >
                                    <label
                                        class="btn btn-light flex-fill"
                                        for="atmosphereRating2"
                                    >
                                        2
                                    </label>
                                    <input
                                        type="radio"
                                        class="btn-check"
                                        id="atmosphereRating3"
                                        formControlName="atmosphere"
                                        autocomplete="off"
                                        [value]="3"
                                    >
                                    <label
                                        class="btn btn-light flex-fill"
                                        for="atmosphereRating3"
                                    >
                                        3
                                    </label>
                                    <input
                                        type="radio"
                                        class="btn-check"
                                        id="atmosphereRating4"
                                        formControlName="atmosphere"
                                        autocomplete="off"
                                        [value]="4"
                                    >
                                    <label
                                        class="btn btn-light flex-fill"
                                        for="atmosphereRating4"
                                    >
                                        4
                                    </label>
                                    <input
                                        type="radio"
                                        class="btn-check"
                                        id="atmosphereRating5"
                                        formControlName="atmosphere"
                                        autocomplete="off"
                                        [value]="5"
                                    >
                                    <label
                                        class="btn btn-light flex-fill"
                                        for="atmosphereRating5"
                                    >
                                        5
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div>
                            <label for="comment" class="form-label">Leave us a comment (optional)</label>
                            <textarea
                                class="form-control"
                                placeholder="Tells us more about your experience"
                                id="comment"
                                formControlName="comment"
                                rows="3"
                            >
                            </textarea>
                        </div>
                    </div>
                    <div class="mt-2 row justify-content-end">
                        <div class="col-auto">
                            <button
                                type="submit"
                                class="btn btn-primary"
                                [disabled]="!feedbackForm.valid"
                                (click)="onSubmitFeedback()"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</app-spinner-overlay>
