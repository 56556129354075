export class BookingFeedback {
    public id: string
    public rating: number
    public food: number | null
    public service: number | null
    public atmosphere: number | null
    public comment: string | null
    public isRedirectedExternally: boolean
    public dateCreated: Date

    constructor(
        id: string,
        rating: number,
        food: number | null,
        service: number | null,
        atmosphere: number | null,
        comment: string | null,
        isRedirectedExternally: boolean,
        dateCreated: Date
    ) {
        this.id = id
        this.rating = rating
        this.food = food
        this.service = service
        this.atmosphere = atmosphere
        this.comment = comment
        this.isRedirectedExternally = isRedirectedExternally
        this.dateCreated = dateCreated
    }
}
