import { Area } from '../domain/Area'
import { Availability } from '../domain/Availability'
import { AvailabilityService } from '../services/availability.service'
import { BookingDetails, BookingType } from '../domain/BookingDetails'
import { BookingReason } from '../domain/BookingReason'
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, } from '@angular/core'
import { ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms'
import { DurationUnit } from '../pipes/duration.pipe'
import { Event } from '../domain/Event'
import { FormStep } from '../booking-form/booking-form.component'
import { NGBDateTimeAdapter } from '../utilities/NgbDateTimeAdapter'
import {
    NgbDate,
    NgbDateNativeAdapter,
    NgbDatepickerConfig,
    NgbDatepickerI18n,
    NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap'
import { Subject, Subscription, takeUntil, tap } from 'rxjs'
import { environment } from '../../environments/environment'
import { GBTimeZoneDatepickerI18n } from '../services/gb-time-zone-datepicker-i18n.service'
import { Helper } from '../utilities/helper'

enum ModifyBookingStep {
    Summary,
    SelectRestrictions,
    SelectTableAndDate,
    SelectBookingType,
    ExplainNonReservableEventBooking,
    SelectReason,
    SelectArea,
    SelectTime,
}

@Component({
    selector: 'app-modal-booking-form',
    templateUrl: './modal-booking-form.component.html',
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: FormGroupDirective,
        },
    ],
    providers: [
        {
            provide: NgbDatepickerI18n,
            useClass: GBTimeZoneDatepickerI18n,
        }
    ],
})
export class ModalBookingFormComponent implements OnInit, OnDestroy {

    @Input() organisationId!: string
    @Input() businessId!: string
    @Input() venueId!: string
    @Input() bookingDetails?: BookingDetails
    @Input() restrictedReasonIds?: string[]
    @Input() preselectedEventId?: string
    @Input() initialIdealTime?: Date
    @Output() saveRequested = new EventEmitter<[FormGroup, Availability]>()
    form!: FormGroup
    earliestDate: NgbDateStruct
    availability: Availability | null = null
    partySizePluralMapping = {
        '=1': '1 person',
        other: '# people',
    }
    venueColour?: string | null
    protected readonly ModifyBookingStep = ModifyBookingStep
    protected ngbTimeAdapter = new NGBDateTimeAdapter()
    private ngbDateAdapter = new NgbDateNativeAdapter()
    private onDestroy$ = new Subject<void>()
    private goNextStepAfterGettingAvailabilitySubscription: Subscription | null = null
    private updateIdealTimeDateAndAdjustToAvailabilitySubscription: Subscription | null = null

    constructor(
        private availabilityService: AvailabilityService,
        private rootFormGroup: FormGroupDirective,
        private datepickerConfig: NgbDatepickerConfig
    ) {
        this.earliestDate = this.ngbDateAdapter.fromModel(new Date())!
    }

    ngOnInit() {
        let formName = FormStep[FormStep.BookingSlot]
        this.form = this.rootFormGroup.form.get(formName) as FormGroup
        this.setFormToBookingDetailsIfNotAlreadySet()
        this.bindFormDateToGettingAvailability()
        this.bindFormTimeToGoingToNextStep()
        this.refreshAvailability()
    }

    ngOnDestroy() {
        this.onDestroy$.next()
        this.onDestroy$.complete()
    }

    selectTableAndDateStep() {
        this.form.get('step')!.setValue(ModifyBookingStep.SelectTableAndDate)
        this.form.get('requiresWheelchairAccess')!.setValue(null)
        if (this.form.get('selectedEventId')!.enabled) {
            this.form.get('bookingType')!.setValue(null)
            this.form.get('selectedEventId')!.setValue(null)
        }
        if (this.form.get('selectedReasonId')!.enabled) {
            this.form.get('selectedReasonId')!.setValue(null)
        }
        this.form.get('selectedAreaId')!.setValue(null)
        this.form.get('time')!.setValue(null)
    }

    selectRestrictionsStep() {
        this.form.get('step')!.setValue(ModifyBookingStep.SelectRestrictions)
        if (this.form.get('selectedEventId')!.enabled) {
            this.form.get('bookingType')!.setValue(null)
            this.form.get('selectedEventId')!.setValue(null)
        }
        if (this.form.get('selectedReasonId')!.enabled) {
            this.form.get('selectedReasonId')!.setValue(null)
        }
        this.form.get('selectedAreaId')!.setValue(null)
        this.form.get('time')!.setValue(null)    }

    selectBookingTypeStep() {
        this.form.get('step')!.setValue(ModifyBookingStep.SelectBookingType)
        if (this.form.get('selectedEventId')!.enabled) {
            this.form.get('bookingType')!.setValue(null)
            this.form.get('selectedEventId')!.setValue(null)
        }
        if (this.form.get('selectedReasonId')!.enabled) {
            this.form.get('selectedReasonId')!.setValue(null)
        }
        this.form.get('selectedAreaId')!.setValue(null)
        this.form.get('time')!.setValue(null)
    }

    selectReasonStep() {
        this.form.get('step')!.setValue(ModifyBookingStep.SelectReason)
        if (this.form.get('selectedEventId')!.enabled) {
            this.form.get('selectedReasonId')!.setValue(null)
        }
        this.form.get('selectedAreaId')!.setValue(null)
        this.form.get('time')!.setValue(null)
    }

    selectAreaStep() {
        this.form.get('step')!.setValue(ModifyBookingStep.SelectArea)
        this.form.get('selectedAreaId')!.setValue(null)
        this.form.get('time')!.setValue(null)
    }

    selectTimeStep() {
        this.form.get('step')!.setValue(ModifyBookingStep.SelectTime)
        this.form.get('time')!.setValue(null)
    }

    checkAvailabilitySelected() {
        if (!this.form.get('date')!.value) {
            return
        }
        this.refreshAvailability()
    }

    private refreshAvailability() {
        this.updateIdealTimeDateAndAdjustToAvailabilitySubscription?.unsubscribe()
        this.getAvailability()
            .pipe(
                takeUntil(this.onDestroy$),
            )
            .subscribe(availability => {
                this.availability = availability
                this.form.get('availability')!.setValue(availability)
                this.goToNextStepAfterGettingAvailability(availability)
            })
    }

    goToNextStepAfterGettingAvailability(availability: Availability) {
        const ngbDate = this.form.get('date')!.value as NgbDate
        const day = this.ngbDateAdapter.toModel(ngbDate)
        const partySize = this.form.get('partySize')!.value
        const idealTime = this.form.get('idealTime')!.value
        const tableAndDateContextFilled = day && partySize && idealTime
        if (!tableAndDateContextFilled) {
            this.form.get('step')!.setValue(ModifyBookingStep.SelectTableAndDate)
            this.form.get('partySize')!.setValue(availability.defaultReservablePartySize())
            const earliestAvailability = availability.earliestAvailability() ?? null
            if (earliestAvailability && earliestAvailability.options.length > 0) {
                const earliestDateTime = earliestAvailability.bookingSlot.dateTime
                const ngbDate = this.ngbDateAdapter.fromModel(earliestDateTime)
                this.form.get('date')!.setValue(ngbDate)
                this.form.get('idealTime')!.setValue(this.initialIdealTime ?? earliestDateTime)
            } else {
                const tomorrow = new Date()
                tomorrow.setDate(tomorrow.getDate() + 1)
                const midday = new Date(tomorrow)
                midday.setHours(12, 0, 0, 0)
                this.form.get('date')!.setValue(this.ngbDateAdapter.fromModel(midday))
                this.form.get('idealTime')!.setValue(midday)
            }
            return
        }
        const alreadySelectedTime = this.form.get('time')!.value
        if (alreadySelectedTime) {
            this.form.get('step')!.setValue(ModifyBookingStep.Summary)
            return
        }
        const hasARestrictedTable = availability.hasARestrictedTable()
        const alreadySelectedRestrictions =
            this.form.get('requiresWheelchairAccess')!.value !== null || this.form.get('requiresDogFriendly')!.value !== null
        if (hasARestrictedTable && !alreadySelectedRestrictions) {
            this.form.get('step')!.setValue(ModifyBookingStep.SelectRestrictions)
            return
        }
        const events = availability.reservableEvents()
        const eventsAreConfigured = events.length > 0
        const canSelectEvents = this.form.get('selectedEventId')!.enabled
        const hasPreselectedEvents = this.preselectedEventId !== null
        if (canSelectEvents && hasPreselectedEvents) {
            const preselectedEvent = events.find(event => event.id === this.preselectedEventId)
            if (preselectedEvent) {
                this.eventSelected(preselectedEvent, availability)
                return
            }
        }
        if (eventsAreConfigured && canSelectEvents) {
            this.form.get('step')!.setValue(ModifyBookingStep.SelectBookingType)
            return
        }
        if (!eventsAreConfigured) {
            this.form.get('bookingType')!.setValue(BookingType.Standard)
        }
        const reasons = availability.venue.reasonsUsedOnDate(day)
        const reasonsAreConfigured = reasons.length > 0
        const isReasonSelectionEnabled = this.form.get('selectedReasonId')!.enabled
        if (reasonsAreConfigured && isReasonSelectionEnabled) {
            this.form.get('step')!.setValue(ModifyBookingStep.SelectReason)
            return
        }
        const userCanSelectArea = availability.venue.areaBookingOrder.length === 0
        this.form.get('areaSelectionIsPossible')!.setValue(userCanSelectArea)
        this.goToAreaSelectionStep(availability)
    }

    restrictionsSelected() {
        const wheelchairAccessControl = this.form.get('requiresWheelchairAccess')!
        if (wheelchairAccessControl.value === null) {
            wheelchairAccessControl.setValue(false)
        }
        const dogFriendlyControl = this.form.get('requiresDogFriendly')!
        if (dogFriendlyControl.value === null) {
            dogFriendlyControl.setValue(false)
        }
        this.getAvailability()
            .pipe(
                takeUntil(this.onDestroy$),
            )
            .subscribe(availability => {
                this.availability = availability
                this.form.get('availability')!.setValue(availability)
                this.goToNextStepAfterGettingAvailability(availability)
            })
    }

    normalBookingTypeSelected(availability: Availability) {
        this.form.get('bookingType')!.setValue(BookingType.Standard)
        this.form.get('selectedEventId')!.setValue(null)
        const ngbDate = this.form.get('date')!.value as NgbDate
        const day = this.ngbDateAdapter.toModel(ngbDate)!
        const reasons = availability.venue.reasonsUsedOnDate(day)
        const reasonsAreConfigured = reasons.length > 0
        if (reasonsAreConfigured) {
            this.form.get('step')!.setValue(ModifyBookingStep.SelectReason)
            return
        }
        const userCanSelectArea = availability.venue.areaBookingOrder.length === 0
        this.form.get('areaSelectionIsPossible')!.setValue(userCanSelectArea)
        this.goToAreaSelectionStep(availability)
    }

    eventSelected(event: Event, availability: Availability) {
        this.form.get('bookingType')!.setValue(BookingType.Event)
        this.form.get('selectedEventId')!.setValue(event.id)
        const userCanSelectArea = availability.venue.areaBookingOrder.length === 0
        this.form.get('areaSelectionIsPossible')!.setValue(userCanSelectArea)
        if (event.notReservableOnline) {
            this.form.get('step')!.setValue(ModifyBookingStep.ExplainNonReservableEventBooking)
        } else {
            this.goToAreaSelectionStep(availability)
        }
    }

    reasonSelected(reason: BookingReason, availability: Availability) {
        this.form.get('selectedReasonId')!.setValue(reason.id)
        const userCanSelectArea = reason.areaBookingOrder === null
        this.form.get('areaSelectionIsPossible')!.setValue(userCanSelectArea)
        this.goToAreaSelectionStep(availability)
    }

    areaSelected(area: Area) {
        this.form.get('selectedAreaId')!.setValue(area.id)
        this.form.get('step')!.setValue(ModifyBookingStep.SelectTime)
    }

    reset() {
        this.form.reset()
        this.setFormToBookingDetailsIfNotAlreadySet()
    }

    save(availability: Availability) {
        this.saveRequested.emit([this.form, availability])
    }

    private getAvailability() {
        const dateTime = this.getFormDate() ?? new Date()
        const requiresWheelchairAccess = this.form.get('requiresWheelchairAccess')!.value
        const requiresDogFriendly = this.form.get('requiresDogFriendly')!.value
        this.availability = null
        return this.availabilityService.getAvailability(
            this.organisationId,
            this.businessId,
            this.venueId,
            dateTime,
            dateTime,
            this.bookingDetails?.bookingId,
            requiresWheelchairAccess ?? undefined,
            requiresDogFriendly ?? undefined,
            this.restrictedReasonIds
        )
            .pipe(
                tap(availability => {
                    this.venueColour = availability.venue.primaryColour
                    this.disablePaymentRelatedOptionsIfDateRestricted()
                    this.disableEventSelectionIfModifyingBooking()
                    this.disableReasonSelectionIfModifyingBooking()
                })
            )
    }

    private setFormToBookingDetailsIfNotAlreadySet() {
        const bookingDetails = this.bookingDetails
        if (!bookingDetails) {
            return
        }
        if (this.form.valid) {
            return
        }
        const bookingType = bookingDetails.eventId ? BookingType.Event : BookingType.Standard
        this.form.setValue({
            availability: null,
            step: ModifyBookingStep.Summary,
            partySize: bookingDetails.partySize,
            idealTime: new Date(bookingDetails.dateTime),
            date: this.ngbDateAdapter.fromModel(bookingDetails.dateTime),
            requiresWheelchairAccess: bookingDetails.requiresWheelchairAccess,
            requiresDogFriendly: bookingDetails.requiresDogFriendly,
            selectedAreaId: bookingDetails.areaId,
            areaSelectionIsPossible: true,
            bookingType: bookingType,
            selectedEventId: bookingDetails.eventId,
            selectedReasonId: bookingDetails.reasonId,
            time: this.ngbTimeAdapter.fromModel(bookingDetails.dateTime),
            endTime: this.ngbTimeAdapter.fromModel(bookingDetails.endTime),
        })
    }

    private getFormDate() {
        const date = this.form.get('date')!.value
        if (!date) {
            return null
        }
        const day = this.ngbDateAdapter.toModel(date)!
        return new Date(
            day.getFullYear(),
            day.getMonth(),
            day.getDate(),
            0,
            0
        )
    }

    private bindFormDateToGettingAvailability() {
        this.form.get('date')!.valueChanges
            .pipe(
                takeUntil(this.onDestroy$),
            )
            .subscribe(() => {
                this.goNextStepAfterGettingAvailabilitySubscription?.unsubscribe()
                this.getAvailability()
                    .pipe(
                        takeUntil(this.onDestroy$)
                    )
                    .subscribe(availability => {
                        this.availability = availability
                        this.form.get('availability')!.setValue(availability)
                        this.updateIdealTimeDateAndAdjustToAvailability(availability)
                    })
            })
    }

    private bindFormTimeToGoingToNextStep() {
        this.form.get('time')!.valueChanges
            .pipe(
                takeUntil(this.onDestroy$)
            )
            .subscribe(time => {
                if (time) {
                    this.form.get('step')!.setValue(ModifyBookingStep.Summary)
                }
            })
    }

    private goToAreaSelectionStep(availability: Availability) {
        const userCanSelectArea = this.form.get('areaSelectionIsPossible')!.value
        if (!userCanSelectArea) {
            this.form.get('step')!.setValue(ModifyBookingStep.SelectTime)
            return
        }
        const areas = availability.areas
        const areaSelectionRequired = areas.length > 1
        if (!areaSelectionRequired) {
            const area = areas[0]
            this.areaSelected(area.area)
            return
        }
        this.form.get('step')!.setValue(ModifyBookingStep.SelectArea)
    }

    get haveBookingDetailsChanged() {
        const bookingDetails = this.bookingDetails
        if (!bookingDetails) {
            return false
        }
        const date = this.getFormDate()
        if (!date) {
            return false
        }
        const ngbTime = this.form.get('time')!.value
        if (!ngbTime) {
            return false
        }
        const time = this.ngbTimeAdapter.toModel(ngbTime)
        if (!time) {
            return false
        }
        const dateTime = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            time.getHours(),
            time.getMinutes(),
            0
        )
        if (dateTime.getTime() !== bookingDetails.dateTime.getTime()) {
            return true
        }
        const areaId = this.form.get('selectedAreaId')!.value
        if (areaId !== bookingDetails.areaId) {
            return true
        }
        const reasonId = this.form.get('selectedReasonId')!.value
        if (reasonId !== bookingDetails.reasonId) {
            return true
        }
        const partySize = this.form.get('partySize')!.value
        if (partySize !== bookingDetails.partySize) {
            return true
        }
        return false
    }

    private disablePaymentRelatedOptionsIfDateRestricted() {
        const bookingDetails = this.bookingDetails
        if (!bookingDetails) {
            return
        }
        const paymentRelatedControls = [
            'partySize',
            'date',
        ]
        if (bookingDetails.isDateRestricted) {
            paymentRelatedControls.forEach(controlName => {
                this.form.get(controlName)!.disable()
            })
        } else {
            paymentRelatedControls.forEach(controlName => {
                this.form.get(controlName)!.enable()
            })
        }
    }

    private disableEventSelectionIfModifyingBooking() {
        const bookingDetails = this.bookingDetails
        if (!bookingDetails) {
            this.form.get('selectedEventId')!.enable()
        } else {
            this.form.get('selectedEventId')!.disable()
        }
    }

    private disableReasonSelectionIfModifyingBooking() {
        const bookingDetails = this.bookingDetails
        if (!bookingDetails) {
            this.form.get('selectedReasonId')!.enable()
        } else {
            this.form.get('selectedReasonId')!.disable()
        }
    }

    private updateIdealTimeDateAndAdjustToAvailability(availability: Availability) {
        const idealTime = this.form.get('idealTime')!.value
        const availabilityIdealTimes = availability.intervalIdealTimes
        const ngbDate = this.form.get('date')!.value as NgbDate
        const date = this.ngbDateAdapter.toModel(ngbDate)
        if (!date) {
            return
        }
        const closestTime = this.closestTimeToTimeInDateTimes(idealTime, date, availabilityIdealTimes)
        if (closestTime) {
            this.form.get('idealTime')!.setValue(closestTime)
        }
    }

    private closestTimeToTimeInDateTimes(time: Date, date: Date, dateTimes: Date[]) {
        const newTime = new Date(time)
        newTime.setFullYear(date.getFullYear(), date.getMonth(), date.getDate())
        const closestTimes = dateTimes
            .sort((a, b) => {
                const aDifference = Math.abs(a.getTime() - newTime.getTime())
                const bDifference = Math.abs(b.getTime() - newTime.getTime())
                return aDifference - bDifference
            })
        if (closestTimes.length === 0) {
            return null
        }
        return closestTimes[0]
    }

    protected readonly DurationUnit = DurationUnit
    protected readonly BookingType = BookingType
    protected readonly environment = environment
    protected readonly Helper = Helper
}
