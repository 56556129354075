import { BookingDetails, BookingType } from '../domain/BookingDetails'
import { BookingService } from '../services/booking.service'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ModelCloningService } from '../services/model-cloning.service'
import { NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap'
import { Observable, Subject, finalize, takeUntil } from 'rxjs'
import { WindowService } from '../services/window/window.service'

@Component({
    selector: 'app-modify-booking',
    templateUrl: './modify-booking.component.html',
})
export class ModifyBookingComponent implements OnInit, OnDestroy {

    @Input() organisationId!: string
    @Input() businessId!: string
    @Input() venueId!: string
    @Input() bookingId!: string
    form: FormGroup
    isLoading = false
    bookingDetails$?: Observable<BookingDetails>
    partySizePluralMapping = {
        '=1': '1 person',
        other: '# people',
    }
    private ngbDateAdapter = new NgbDateNativeAdapter()
    private onDestroy$ = new Subject<void>()

    constructor(
        private bookingService: BookingService,
        private fb: FormBuilder,
        private modelCloningService: ModelCloningService,
        private windowService: WindowService
    ) {
        this.form = this.makeForm()
    }

    ngOnInit() {
        this.loadBookingDetails()
        this.bindBookingDetailsToRedirectingToViewBookingIfCannotModify()
    }

    ngOnDestroy() {
        this.onDestroy$.next()
        this.onDestroy$.complete()
    }

    save(form: FormGroup, bookingDetails: BookingDetails) {
        if (form.invalid) {
            return
        }
        const day = this.ngbDateAdapter.toModel(form.get('date')!.value)
        if (!day) {
            return
        }
        const reasonId = form.get('selectedReasonId')!.value
        const areaId = form.get('selectedAreaId')!.value
        const partySize = form.get('partySize')!.value
        const time = form.get('time')!.value
        const dateTime = new Date(
            day.getFullYear(),
            day.getMonth(),
            day.getDate(),
            time.hour,
            time.minute
        )
        const updatedBookingDetails = this.modelCloningService.cloneBookingDetails(bookingDetails)
        updatedBookingDetails.dateTime = dateTime
        updatedBookingDetails.reasonId = reasonId
        updatedBookingDetails.areaId = areaId
        updatedBookingDetails.partySize = partySize
        this.isLoading = true
        this.bookingService.modifyBooking(
            this.organisationId,
            this.businessId,
            this.venueId,
            bookingDetails,
            updatedBookingDetails
        )
            .pipe(
                takeUntil(this.onDestroy$),
                finalize(() => this.isLoading = false)
            )
            .subscribe(() => {
                this.windowService.navigateToUrl(`booking/${this.bookingId}`)
            })
    }

    private loadBookingDetails() {
        this.isLoading = true
        this.bookingDetails$ = this.bookingService.getBookingDetails(this.bookingId)
            .pipe(
                takeUntil(this.onDestroy$),
                finalize(() => this.isLoading = false)
            )
    }

    private bindBookingDetailsToRedirectingToViewBookingIfCannotModify() {
        this.bookingDetails$
            ?.pipe(
                takeUntil(this.onDestroy$)
            )
            .subscribe(bookingDetails => {
                if (!bookingDetails.modifyBookingPath) {
                    this.windowService.navigateToUrl(`booking/${this.bookingId}`)
                }
            })
    }

    private makeForm() {
        return this.fb.group({
            'BookingSlot': this.fb.group({
                availability: [null, Validators.required],
                step: [null, Validators.required],
                partySize: [null, Validators.required],
                idealTime: [null, Validators.required],
                date: [null, Validators.required],
                requiresWheelchairAccess: [null],
                requiresDogFriendly: [null],
                selectedAreaId: [null, Validators.required],
                areaSelectionIsPossible: [true, Validators.required],
                bookingType: [BookingType.Standard, Validators.required],
                selectedEventId: [null],
                selectedReasonId: [null],
                time: [null, Validators.required],
                endTime: [null],
            }),
        })
    }
}
