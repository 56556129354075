import { Address } from '../../domain/Address'
import { AddressDTO } from './AddressDTO'
import { Answer } from '../../domain/Answer'
import { AnswerDTO } from './AnswerDTO'
import { Area } from '../../domain/Area'
import { AreaAvailability } from '../../domain/AreaAvailability'
import { AreaDTO } from './AreaDTO'
import { Availability } from '../../domain/Availability'
import { AvailabilityDTO } from './AvailabilityDTO'
import { BookingDetails } from '../../domain/BookingDetails'
import { BookingDetailsDTO } from './BookingDetailsDTO'
import { BookingFeedback } from '../../domain/BookingFeedback'
import { BookingFeedbackDTO } from './BookingFeedbackDTO'
import { BookingReason } from '../../domain/BookingReason'
import { BookingReasonDTO } from './BookingReasonDTO'
import { BookingRequest } from '../../domain/BookingRequest'
import { BookingRequestDTO } from './BookingRequestDTO'
import { BookingSlot } from '../../domain/BookingSlot'
import { BookingSlotAvailability } from '../../domain/BookingSlotAvailability'
import { BookingSlotDTO } from './BookingSlotDTO'
import { BookingSlotOption } from '../../domain/BookingSlotOption'
import { BookingStatusType } from '../../domain/BookingStatus'
import { BookingStatusTypeDTO } from './BookingStatusTypeDTO'
import { ContactDetailsDTO } from './ContactDetailsDTO'
import { Event, EventRecurrence } from '../../domain/Event'
import { EventDTO, EventRecurrenceDTO } from './EventDTO'
import { Injectable } from '@angular/core'
import { Period } from '../../domain/Period'
import { PeriodDTO } from './PeriodDTO'
import { Question, QuestionType } from '../../domain/Question'
import { QuestionDTO, QuestionTypeDTO } from './QuestionDTO'
import { ReasonDateRangeFilter } from '../../domain/ReasonDateRangeFilter'
import { ReasonDateRangeFilterDTO } from './ReasonDateRangeFilterDTO'
import { ReasonScheduleRule } from '../../domain/ReasonScheduleRule'
import { ReasonScheduleRuleDTO } from './ReasonScheduleRuleDTO'
import { Table } from '../../domain/Table'
import { TableDTO } from './TableDTO'
import { Time } from '../../domain/Time'
import { Venue } from '../../domain/Venue'
import { VenueDTO } from './VenueDTO'
import { Voucher } from '../../domain/Voucher'
import { VoucherDTO } from './VoucherDTO'
import { SKUDTO } from './SKUDTO'
import { SKU } from '../../domain/SKU'

@Injectable({
    providedIn: 'root',
})
export class DTOAdapter {

    adaptAvailabilityDto(dto: AvailabilityDTO): Availability {
        const areas = dto.areas.map(dto => {
            const area = this.adaptAreaDto(dto.area)
            const bookingSlots = dto.bookingSlots.map(dto => {
                return new BookingSlotAvailability(
                    new BookingSlot(new Date(dto.bookingSlot.dateTime)),
                    dto.options.map(dto => {
                        return new BookingSlotOption(
                            dto.partySize,
                            dto.durationMinutes,
                            dto.allTableCount,
                            dto.reservableTableCount,
                            dto.reasonId,
                            dto.eventId,
                            dto.cancellationChargeAmount,
                            dto.cancellationChargeIsPerCover,
                            dto.depositAmount,
                            dto.depositAmountIsPerCover
                        )
                    })
                )
            })
            return new AreaAvailability(area, bookingSlots, dto.exceptionDescription)
        })
        const venue = this.adaptVenueDto(dto.venue)
        return new Availability(areas, venue)
    }

    adaptVenueDto(dto: VenueDTO): Venue {
        const reasons = dto.reasons.map(dto => this.adaptBookingReasonDto(dto))
        const questions = dto.questions.map(dto => this.adaptQuestionDto(dto))
        const events = dto.events.map(dto => this.adaptEventDto(dto))
        return new Venue(
            dto.id,
            dto.displayName,
            dto.phoneNumber,
            dto.emailAddress,
            this.adaptAddressDto(dto.address),
            dto.bookingInterval,
            dto.minLargePartySize,
            dto.largePartyMessage,
            dto.noBookingSlotAvailableMessage,
            dto.shortPreBookingWindowMessage,
            dto.currencyCode,
            dto.organisationId,
            dto.businessId,
            dto.paymentsAccountId,
            dto.cancellationChargeAmount,
            dto.cancellationChargeCutOffHours,
            dto.depositAmount,
            dto.depositRefundCutOffDays,
            dto.phoneNumberRequired,
            dto.primaryColour,
            dto.pinnedEventId,
            [...dto.areaBookingOrder],
            reasons,
            questions,
            events
        )
    }

    adaptBookingReasonDto(dto: BookingReasonDTO): BookingReason {
        return new BookingReason(
            dto.id,
            dto.displayName,
            dto.displayOrder,
            dto.description,
            dto.schedule.map(dto => this.adaptReasonScheduleRuleDto(dto)),
            dto.dateRangeFilters.map(dto => this.adaptReasonDateRangeFilterDto(dto)),
            dto.areaBookingOrder ? [...dto.areaBookingOrder] : null,
            dto.diningInformation
        )
    }

    adaptReasonScheduleRuleDto(dto: ReasonScheduleRuleDTO): ReasonScheduleRule {
        return new ReasonScheduleRule(
            dto.id,
            dto.daysOfWeek,
            dto.period ? this.adaptPeriodDto(dto.period) : null
        )
    }

    adaptReasonDateRangeFilterDto(dto: ReasonDateRangeFilterDTO): ReasonDateRangeFilter {
        return new ReasonDateRangeFilter(
            dto.id,
            new Date(dto.startDate),
            new Date(dto.endDate)
        )
    }

    adaptQuestionDto(dto: QuestionDTO): Question {
        return new Question(
            dto.id,
            dto.text,
            dto.required,
            this.adaptQuestionTypeDto(dto.type),
            [...dto.options],
            [...dto.reasonIds]
        )
    }

    adaptQuestionType(type: QuestionType): QuestionTypeDTO {
        switch (type) {
        case QuestionType.Checkbox:
            return QuestionTypeDTO.Checkbox
        case QuestionType.Dropdown:
            return QuestionTypeDTO.Dropdown
        case QuestionType.AcknowledgeEndTime:
            return QuestionTypeDTO.AcknowledgeEndTime
        case QuestionType.DOB:
            return QuestionTypeDTO.DOB
        case QuestionType.ReasonsForVisit:
            return QuestionTypeDTO.ReasonsForVisit
        }
    }

    adaptQuestionTypeDto(dto: QuestionTypeDTO): QuestionType {
        switch (dto) {
        case QuestionTypeDTO.Checkbox:
            return QuestionType.Checkbox
        case QuestionTypeDTO.Dropdown:
            return QuestionType.Dropdown
        case QuestionTypeDTO.AcknowledgeEndTime:
            return QuestionType.AcknowledgeEndTime
        case QuestionTypeDTO.DOB:
            return QuestionType.DOB
        case QuestionTypeDTO.ReasonsForVisit:
            return QuestionType.ReasonsForVisit
        }
    }

    adaptAreaDto(dto: AreaDTO): Area {
        return new Area(
            dto.id,
            dto.displayName,
            dto.description,
            dto.displayOrder,
            dto.preBookingWindowMinutes,
            new Date(dto.cutOffTime),
            dto.tables.map(dto => this.adaptTableDto(dto))
        )
    }

    adaptAddressDto(dto: AddressDTO): Address {
        return new Address(
            dto.addressLineOne,
            dto.addressLineTwo,
            dto.city,
            dto.county,
            dto.postCode,
            dto.country
        )
    }

    adaptTableDto(dto: TableDTO): Table {
        return new Table(
            dto.reservable,
            dto.minimumSeats,
            dto.maximumSeats,
            dto.wheelchairAccessible,
            dto.dogFriendly
        )
    }

    adaptEventDto(dto: EventDTO): Event {
        return new Event(
            dto.id,
            dto.displayName,
            dto.displayOrder,
            dto.description,
            dto.imageId,
            dto.cancellationChargeAmount,
            dto.cancellationChargePartyAmount,
            dto.cancellationChargeCutOffHours,
            dto.depositAmount,
            dto.depositRefundCutOffDays,
            [...dto.questionIds],
            dto.notReservableOnline,
            dto.notReservableOnlineMessage,
            this.adaptEventRecurrenceDto(dto.recurrence)
        )
    }

    adaptEventRecurrenceDto(dto: EventRecurrenceDTO): EventRecurrence {
        return new EventRecurrence(
            dto.startDates.map(dto => new Date(dto)),
            dto.endDate ? new Date(dto.endDate) : null
        )
    }

    adaptBookingDetails(details: BookingDetails): BookingDetailsDTO {
        return new BookingDetailsDTO(
            details.bookingId,
            details.currencyCode,
            details.venueId,
            details.venueName,
            this.adaptAddressDto(details.venueAddress),
            details.venueDiningInformation,
            details.venuePhoneNumber,
            details.bookingName,
            details.partySize,
            details.durationMinutes,
            this.makeLocalISOFormattedDateTimeString(details.dateTime),
            this.adaptBookingStatusType(details.statusType),
            details.cancellationChargeAmount,
            details.cancellationChargeCutOffTime,
            details.areaId,
            details.areaName,
            details.reasonId,
            details.reasonName,
            details.reasonDiningInformation,
            details.eventId,
            details.eventName,
            details.eventImageUrl,
            details.depositAmount,
            details.depositCurrencyCode,
            details.depositDatePaid ? details.depositDatePaid.toISOString() : null,
            details.depositDateRefunded ? details.depositDateRefunded.toISOString() : null,
            details.depositRefundCutOffTime ? details.depositRefundCutOffTime.toISOString() : null,
            details.requiresWheelchairAccess,
            details.requiresDogFriendly,
            details.modifyBookingPath ? details.modifyBookingPath : null,
            details.pendingPaymentExpiryDateTime
                ? this.makeLocalISOFormattedDateTimeString(details.pendingPaymentExpiryDateTime)
                : null
        )
    }

    adaptBookingDetailsDto(dto: BookingDetailsDTO): BookingDetails {
        return new BookingDetails(
            dto.bookingId,
            dto.currencyCode,
            dto.venueId,
            dto.venueName,
            this.adaptAddressDto(dto.venueAddress),
            dto.venueDiningInformation,
            dto.venuePhoneNumber,
            dto.bookingName,
            dto.partySize,
            dto.durationMinutes,
            new Date(dto.dateTime),
            this.adaptBookingStatusTypeDto(dto.statusType),
            dto.cancellationChargeAmount,
            dto.cancellationChargeCutOffTime,
            dto.areaId,
            dto.areaName ? dto.areaName : null,
            dto.reasonId,
            dto.reasonName,
            dto.reasonDiningInformation,
            dto.eventId,
            dto.eventName,
            dto.eventImageUrl,
            dto.depositAmount,
            dto.depositCurrencyCode,
            dto.depositDatePaid ? new Date(dto.depositDatePaid) : null,
            dto.depositDateRefunded ? new Date(dto.depositDateRefunded) : null,
            dto.depositRefundCutOffTime ? new Date(dto.depositRefundCutOffTime) : null,
            dto.requiresWheelchairAccess,
            dto.requiresDogFriendly,
            dto.modifyBookingPath ? dto.modifyBookingPath : null,
            dto.pendingPaymentExpiryDateTime ? new Date(dto.pendingPaymentExpiryDateTime) : null
        )
    }

    adaptBookingFeedback(feedback: BookingFeedback): BookingFeedbackDTO {
        return new BookingFeedbackDTO(
            feedback.id,
            feedback.rating,
            feedback.food,
            feedback.service,
            feedback.atmosphere,
            feedback.comment,
            feedback.isRedirectedExternally,
            feedback.dateCreated
        )
    }

    adaptBookingFeedbackDto(dto: BookingFeedbackDTO): BookingFeedback {
        return new BookingFeedback(
            dto.id,
            dto.rating,
            dto.food,
            dto.service,
            dto.atmosphere,
            dto.comment,
            dto.isRedirectedExternally,
            dto.dateCreated
        )
    }

    private adaptBookingStatusType(type: BookingStatusType): BookingStatusTypeDTO {
        switch (type) {
        case BookingStatusType.Booked:
            return BookingStatusTypeDTO.Booked
        case BookingStatusType.NoShow:
            return BookingStatusTypeDTO.NoShow
        case BookingStatusType.Cancelled:
            return BookingStatusTypeDTO.Cancelled
        case BookingStatusType.Seated:
            return BookingStatusTypeDTO.Seated
        case BookingStatusType.Finished:
            return BookingStatusTypeDTO.Finished
        case BookingStatusType.Rejected:
            return BookingStatusTypeDTO.Rejected
        case BookingStatusType.Requested:
            return BookingStatusTypeDTO.Requested
        case BookingStatusType.PendingPayment:
            return BookingStatusTypeDTO.PendingPayment
        }
    }

    private adaptBookingStatusTypeDto(dto: BookingStatusTypeDTO): BookingStatusType {
        switch (dto) {
        case BookingStatusTypeDTO.Booked:
            return BookingStatusType.Booked
        case BookingStatusTypeDTO.NoShow:
            return BookingStatusType.NoShow
        case BookingStatusTypeDTO.Cancelled:
            return BookingStatusType.Cancelled
        case BookingStatusTypeDTO.Seated:
            return BookingStatusType.Seated
        case BookingStatusTypeDTO.Finished:
            return BookingStatusType.Finished
        case BookingStatusTypeDTO.Rejected:
            return BookingStatusType.Rejected
        case BookingStatusTypeDTO.Requested:
            return BookingStatusType.Requested
        case BookingStatusTypeDTO.PendingPayment:
            return BookingStatusType.PendingPayment
        }
    }

    adaptBookingRequest(bookingRequest: BookingRequest): BookingRequestDTO {
        const answers = bookingRequest.answers.map(answer => this.adaptAnswer(answer))
        return new BookingRequestDTO(
            new BookingSlotDTO(bookingRequest.bookingSlot.toLocalISOFormattedDateTimeString()),
            new ContactDetailsDTO(
                bookingRequest.firstName,
                bookingRequest.lastName,
                bookingRequest.emailAddress,
                bookingRequest.phoneNumber
            ),
            bookingRequest.partySize,
            bookingRequest.notes,
            bookingRequest.agreedToMarketing,
            bookingRequest.agreedToTerms,
            bookingRequest.setupIntentId,
            bookingRequest.reasonId,
            bookingRequest.eventId,
            answers,
            bookingRequest.depositPaymentIntentId,
            bookingRequest.requiresWheelchairAccess,
            bookingRequest.requiresDogFriendly
        )
    }

    adaptBookingSlotDto(dto: BookingSlotDTO): BookingSlot {
        return new BookingSlot(new Date(dto.dateTime))
    }

    adaptBookingSlot(bookingSlot: BookingSlot): BookingSlotDTO {
        return new BookingSlotDTO(bookingSlot.toLocalISOFormattedDateTimeString())
    }

    adaptPeriod(period: Period): PeriodDTO {
        return new PeriodDTO(
            period.start.toString(),
            period.end.toString(),
            period.open
        )
    }

    adaptPeriodDto(dto: PeriodDTO): Period {
        return new Period(
            new Time(dto.start, null, null),
            new Time(dto.end, null, null),
            dto.open
        )
    }

    adaptVoucherDto(dto: VoucherDTO): Voucher {
        return new Voucher(
            dto.id,
            dto.displayName,
            dto.description,
            dto.skus.map(dto => this.adaptSKUDto(dto))
        )
    }

    adaptSKUDto(dto: SKUDTO): SKU {
        return new SKU(
            dto.id,
            dto.price,
            dto.onSale
        )
    }

    private adaptAnswer(answer: Answer): AnswerDTO {
        return new AnswerDTO(
            answer.question.id,
            answer.question.text,
            answer.question.required,
            this.adaptQuestionType(answer.question.type),
            answer.answer
        )
    }

    private makeLocalISOFormattedDateTimeString(date: Date): string {
        let offsetMs = date.getTimezoneOffset() * 60 * 1000
        let msLocal = date.getTime() - offsetMs
        let dateLocal = new Date(msLocal)
        let iso = dateLocal.toISOString()
        return iso
    }
}
