<button
    appVenueColour
    [primaryColour]="venueColour"
    class="btn btn-lg btn-primary position-absolute top-0 start-0 end-0 bottom-0 w-100 h-100"
    (click)="openModal()"
>
    <div class="justify-content-center">
        Buy a Voucher
    </div>
</button>
