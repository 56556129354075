import { ActivatedRoute } from '@angular/router'
import { BookingFeedback } from '../domain/BookingFeedback'
import { BookingFeedbackService } from '../services/booking-feedback.service'
import { BookingService } from '../services/booking.service'
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { Helper } from '../utilities/helper'
import { v4 as uuidv4 } from 'uuid'
import { finalize, Observable } from 'rxjs'
import { BookingDetails } from '../domain/BookingDetails'

export enum FeedbackFormStep {
    FeedbackForm = 'FeedbackForm',
    Confirmation = 'FeedbackConfirmation',
}

@Component({
    selector: 'app-booking-feedback',
    templateUrl: './booking-feedback.component.html',
})
export class BookingFeedbackComponent implements OnInit {
    bookingId: string
    booking$?: Observable<BookingDetails>
    rating: number | null
    food: number | null
    service: number | null
    atmosphere: number | null
    reviewCount: number | null
    redirectUrl: string | null
    isLoading = true
    isSubmitting = false
    feedbackRequestFormError: string | null = null
    form: FormGroup
    step: FeedbackFormStep
    formStep = FeedbackFormStep

    constructor(
        private fb: FormBuilder,
        private bookingService: BookingService,
        private bookingFeedbackService: BookingFeedbackService,
        private route: ActivatedRoute
    ) {
        this.step = FeedbackFormStep.FeedbackForm
        this.bookingId = route.snapshot.params['bookingId']
        this.booking$ = this.bookingService.getBookingDetails(this.bookingId)
        this.rating = Helper.makeNumberOrNull(route.snapshot.queryParams['rating'])
        this.food = null
        this.service = null
        this.atmosphere = null
        this.reviewCount = Helper.makeNumberOrNull(route.snapshot.queryParams['count'])
        this.redirectUrl = route.snapshot.queryParams['redirect_url']
        this.form = this.makeForm()
    }

    ngOnInit() {
        this.isLoading = true
        this.redirectToExternalReviewUrl()
            .finally(() => {
                this.isLoading = false
            })
            .then((didRedirect) => {
                if (!didRedirect) {
                    this.booking$ = this.bookingService.getBookingDetails(this.bookingId)
                }
            })
    }

    private makeForm() {
        return this.fb.group({
            'FeedbackForm': this.fb.group({
                rating: new FormControl(this.rating, Validators.min(1)),
                food: new FormControl(this.food),
                service: new FormControl(this.service),
                atmosphere: new FormControl(this.atmosphere),
                comment: new FormControl(null),
            }),
        })
    }

    onSubmitFeedbackSelected() {
        this.isSubmitting = true
        this.submitFeedback()
        this.isSubmitting = false
    }

    private submitFeedback() {
        let feedbackForm = this.form.get(FeedbackFormStep.FeedbackForm) as FormGroup
        let feedback = new BookingFeedback(
            uuidv4(),
            feedbackForm.get('rating')!.value,
            feedbackForm.get('food')!.value,
            feedbackForm.get('service')!.value,
            feedbackForm.get('atmosphere')!.value,
            feedbackForm.get('comment')!.value,
            false,
            new Date()
        )

        this.bookingService.submitFeedback(this.bookingId, feedback)
            .then(_ => {
                this.step = FeedbackFormStep.Confirmation
            })
            .catch(_ => {
                this.feedbackRequestFormError = 'There was an error submitting your feedback. Please refresh the page and try again.'
            })
    }

    private async redirectToExternalReviewUrl(): Promise<boolean> {
        const rating = this.rating
        let reviewUrl = this.bookingFeedbackService.makeReviewUrlFromQueryParam(this.redirectUrl)
        const shouldRedirect = this.bookingFeedbackService.shouldRedirect(rating, this.reviewCount)
        const canRedirect = reviewUrl !== null && rating !== null
        if (shouldRedirect && canRedirect) {
            let isReviewingExternallyFeedback = new BookingFeedback(
                uuidv4(),
                rating,
                null,
                null,
                null,
                null,
                true,
                new Date()
            )
            await this.bookingService.submitFeedback(this.bookingId, isReviewingExternallyFeedback)
            //listen to the response and redirect to the reviewUrl
            window.location.href = reviewUrl
            return true
        }
        return false
    }

}
