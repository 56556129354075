import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { DTOAdapter } from './DTOs/DTOAdapter'
import { Observable } from 'rxjs'
import { Venue } from '../domain/Venue'
import { environment } from '../../environments/environment'
import { map } from 'rxjs/operators'
import { Voucher } from '../domain/Voucher'
import { VoucherDTO } from './DTOs/VoucherDTO'

@Injectable({
  providedIn: 'root'
})
export class VoucherService {

    constructor(
        private http: HttpClient,
        private dtoAdapter: DTOAdapter
    ) { }

    getWithId(venue: Venue, voucherId: string): Observable<Voucher> {
        const path = `/product-library/venue/${venue.id}/voucher/${voucherId}`
        const url = new URL(path, environment.apiBaseUrl)
        return this.http.get<VoucherDTO>(url.toString())
            .pipe(
                map(dto => this.dtoAdapter.adaptVoucherDto(dto))
            )
    }
}
