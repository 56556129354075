<div
    class="d-block modal fade show"
    appVenueColour
>
    @if (venue$ | async; as venue) {
        <div
            class="text-white small position-absolute bottom-0 d-none d-sm-flex gap-2 justify-content-center align-items-center w-100 p-3"
        >
            {{ venue.displayName }}
            collaborates with TableSense to simplify operations and create lasting guest experiences.
            <a
                [href]="marketingUrl"
                target="_blank"
            >
                Learn more
            </a>
        </div>
        <div class="modal-fullscreen-sm-down container p-0 d-flex flex-column mt-0 mb-0 align-items-center justify-content-center min-vh-100">
            <div class="modal-content m-0 mb-sm-3">
                <ng-container
                    *ngTemplateOutlet="header"
                >
                </ng-container>
                <ng-container
                    *ngTemplateOutlet="body"
                >
                </ng-container>
            </div>
        </div>
    }
</div>

<ng-template
    #header
>
    <div class="modal-header d-flex justify-content-between">
        <h5 class="modal-title fw-semibold">
            Your Order
        </h5>
        <button
            type="button"
            class="btn-close"
            (click)="dismiss()"
        >
        </button>
    </div>
</ng-template>

<ng-template
    #body
>
    <div
        class="modal-body px-4 py-3 pb-4 flex-fill"
        [formGroup]="form"
    >
        @if (venue$ | async; as venue) {
            @if (voucher$ | async; as voucher) {
                <div class="vstack gap-2">
                    <h5 class="fw-semibold">
                        {{ voucher.displayName }}
                    </h5>
                    <div class="hstack gap-4">
                        <div class="w-50 vstack gap-3">
                            <div class="bg-dark-subtle rounded">
                                <div style="width: 100%; height: 200px;"></div>
                            </div>
                            <div class="vstack gap-1">
                                <h6 class="fw-semibold">
                                    Details
                                </h6>
                                <p>
                                    This voucher is valid for 12 months from the date of purchase.
                                    Delivery is free and will be sent to you, or your preferred recipient, via email.
                                </p>
                                <ul class="list-group">
                                    <li class="list-group-item hstack justify-content-between">
                                        <span class="fw-semibold">
                                            Terms & Conditions
                                        </span>
                                        <img
                                            ngSrc="assets/right/normal.svg"
                                            width="16"
                                            height="16"
                                            alt="Terms & Conditions"
                                        >
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="w-50 vstack gap-1">
                            @if (voucher.description) {
                                <h6 class="fw-semibold">
                                    Description
                                </h6>
                                <p>
                                    {{ voucher.description }}
                                </p>
                            }
                            @if (voucher.isOnSale) {
                                @let sku = voucher.lowestPricedSKU;
                                @let lowestSKUPrice = null | currencyUnit : sku?.price : 'GBP';
                                <div class="vstack gap-3">
                                    <div class="vstack flex-grow-0">
                                        <h6 class="text-secondary m-0">
                                            From
                                        </h6>
                                        <span class="fw-semibold fs-5">
                                            {{ lowestSKUPrice }}
                                        </span>
                                    </div>
                                    <div class="vstack gap-1 flex-grow-0">
                                        <h6 class="text-secondary m-0">
                                            Value
                                            @if (form.get('skuId')?.touched && form.get('skuId')?.invalid) {
                                                <small class="text-danger">
                                                    Please select an amount
                                                </small>
                                            }
                                        </h6>
                                        <div class="position-relative btn-group dropdown">
                                            <button
                                                class="btn btn-outline-secondary dropdown-toggle"
                                                type="button"
                                                (click)="skuSelect.click()"
                                            >
                                                @if (!selectedSKU) {
                                                    Select Amount
                                                } @else {
                                                    {{ null | currencyUnit : selectedSKU.price : 'GBP' }}
                                                }
                                            </button>
                                            <select
                                                #skuSelect
                                                class="form-select btn opacity-0 position-absolute top-0 start-0 w-100 h-100"
                                                formControlName="skuId"
                                            >
                                                @for (sku of voucher.skusSortedByPriceLowestFirst; track sku.id) {
                                                    <option
                                                        [value]="sku.id"

                                                    >
                                                        {{ null | currencyUnit : sku.price : 'GBP' }}
                                                    </option>
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <button
                                        class="btn btn-primary w-100"
                                        appVenueColour
                                        [primaryColour]="venue.primaryColour"
                                        (click)="goToCheckout()"
                                    >
                                        Buy Now
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
        }
    </div>
</ng-template>
