export class BookingFeedbackDTO {
    constructor(
        public id: string,
        public rating: number,
        public food: number | null,
        public service: number | null,
        public atmosphere: number | null,
        public comment: string | null,
        public isRedirectedExternally: boolean,
        public dateCreated: Date
    ) { }
}
