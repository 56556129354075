import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FeedbackFormStep } from '../booking-feedback/booking-feedback.component'
import { FormGroup, FormGroupDirective } from '@angular/forms'
import { BookingDetails } from '../domain/BookingDetails'

@Component({
    selector: 'app-booking-feedback-form',
    templateUrl: './booking-feedback-form.component.html',
})
export class BookingFeedbackFormComponent implements OnInit {

    @Input() booking!: BookingDetails
    @Output() submitFeedbackSelected = new EventEmitter<void>()

    feedbackForm!: FormGroup
    isSendingFeedback = false

    constructor(
        private rootFormGroup: FormGroupDirective
    ) { }

    ngOnInit() {
        let formName = FeedbackFormStep[FeedbackFormStep.FeedbackForm]
        this.feedbackForm = this.rootFormGroup.control.get(formName) as FormGroup
    }

    onSubmitFeedback() {
        this.isSendingFeedback = true
        this.submitFeedbackSelected.emit()
    }
}
